@tailwind base;
@tailwind components;
@tailwind utilities;

/*::-webkit-scrollbar {*/
/*    width: 1em;*/
/*}*/

/*::-webkit-scrollbar-track {*/
/*    background-color: rgb(45, 45, 45);*/
/*}*/

/*::-webkit-scrollbar-thumb {*/
/*    background-color: rgb(212, 29, 68);*/
/*    border-radius: 25px;*/
/*    border: 0.2em solid transparent;*/
/*    background-clip: padding-box;*/
/*}*/

body {
    font-family: var(--font-montserrat);
}
